import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  standalone: true,
})
export class UserIconComponent implements OnInit {
  @Input() color: string | undefined;
  @Input() width: string = '14';
  @Input() height: string = '15';
  ngOnInit() {}
}
