<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 16 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15.8948 13.6799C14.8792 11.9477 13.2933 10.5979 11.3924 9.84799C12.3377 9.15902 13.0359 8.19847 13.3882 7.10241C13.7406 6.00636 13.7291 4.83035 13.3554 3.74099C12.9818 2.65163 12.2649 1.70413 11.3063 1.03271C10.3477 0.361298 9.19605 0 8.01444 0C6.83282 0 5.68116 0.361298 4.72258 1.03271C3.76401 1.70413 3.04711 2.65163 2.67345 3.74099C2.29979 4.83035 2.28831 6.00636 2.64063 7.10241C2.99295 8.19847 3.69121 9.15902 4.6365 9.84799C2.73562 10.5979 1.14971 11.9477 0.134073 13.6799C0.0711401 13.7783 0.0291959 13.8879 0.0107336 14.0023C-0.00772877 14.1167 -0.00233248 14.2336 0.0266018 14.3459C0.055536 14.4582 0.107417 14.5638 0.179162 14.6562C0.250906 14.7487 0.341049 14.8262 0.444231 14.8841C0.547413 14.9421 0.661527 14.9792 0.779791 14.9935C0.898054 15.0077 1.01805 14.9986 1.13265 14.9669C1.24725 14.9351 1.35411 14.8813 1.44688 14.8086C1.53965 14.736 1.61643 14.6459 1.67266 14.5438C3.01495 12.2891 5.38543 10.9444 8.01444 10.9444C10.6434 10.9444 13.0139 12.2898 14.3562 14.5438C14.4781 14.7342 14.6714 14.8711 14.8952 14.9256C15.1191 14.98 15.356 14.9478 15.5558 14.8356C15.7556 14.7234 15.9027 14.5401 15.9659 14.3244C16.0292 14.1088 16.0037 13.8776 15.8948 13.6799ZM4.16241 5.47327C4.16241 4.73289 4.38832 4.00915 4.81159 3.39355C5.23486 2.77795 5.83646 2.29815 6.54033 2.01483C7.24419 1.7315 8.01871 1.65737 8.76593 1.80181C9.51315 1.94625 10.1995 2.30277 10.7382 2.82629C11.2769 3.34981 11.6438 4.01682 11.7925 4.74297C11.9411 5.46911 11.8648 6.22179 11.5732 6.9058C11.2817 7.58981 10.788 8.17445 10.1545 8.58578C9.52105 8.99711 8.7763 9.21666 8.01444 9.21666C6.99318 9.21551 6.01408 8.82075 5.29194 8.11898C4.5698 7.41721 4.16358 6.46572 4.16241 5.47327Z"
    [attr.fill]="color != null || color != undefined ? color : '#4B4B4B'"
    
  />
</svg>
